<template>
  <div
    class="media-image col-1"
    >
      <div 
        class="position-relative ratio ratio-1x1 border-radius-lg overflow-hidden mb-3"
      >
        <img
          :alt="title"
          class="summary-visual__image"
          :src="image.src ?? image.data"
        />

        <button
          class="summary-visual-remove position-absolute top-0 start-0 bottom-0 p-0 border-0
                  w-100 bg-transparent"
          @click.prevent="remove"
        >
        <div class="summary-visual-remove__background w-100 h-100 bg-danger" />

        <i
          class="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-2"
        >
          delete_outline
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import  { createVModelHandlers } from '@/helpers/vue';

import { createYesNoSweetAlert } from '@/helpers/sweetalert';


export default {
  name: 'ImageUploadItem',

  props: {
    image: { type: Object, required: true },
  },

  emits: [
    'update'
  ],

  computed: {
    removeButtonTitle() { 
      return this.isExistingFile ?
        "Bestaand item verwijderen" :
        "Upload annuleren";
    },
    removeButtonIcon() { return this.isExistingFile ? "delete" : "close"; }, 
    isExistingFile() { return this.id > 0; },
    ...createVModelHandlers(
        'filename'
    ),

  },

  methods: {
    remove() {
      createYesNoSweetAlert(
        'Weet u zeker dat u deze afbeelding wilt verwijderen?',
        () => {
          this.$emit('update', { ...this.image, isDeleted: true });
        }
      )
    },
  }
}
</script>

<style scoped lang="scss">
.media-image {
  width: 150px;

  img {
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
  }

  button { 
    opacity: 0.0;
  }
  
  button:hover {
    opacity: 1.0;
    transition: opacity 200ms;
  }
}

</style>