<template>
  <h5>{{ title }}</h5>
  <div 
    v-if="imagesArePresent" 
    class="file-container row mb-3"
  >
    <ImageUploadItem 
      v-for="image in filteredImages"
      :key="image.key"
      :image="image"
      @update="updateImage" 
    />
  </div>
  <p 
    v-if="!imagesArePresent"
  >
    Nog geen afbeeldingen geüpload
  </p>
  <div class="row">
    <div class="col-6">
      <input 
        id="image-upload" 
        class="d-none" 
        type="file" 
        accept="image/*" 
        multiple 
        @change="handleLocalFileUpload"
      />
      <label 
        class="btn btn-primary" 
        for="image-upload"
      >
        Selecteer afbeeldingen
      </label>
    </div>
    <div class="col-6">
      <material-button
        v-if="showUpdate"
        class="float-end mb-0"
        color="dark"
        variant="gradient"
        size="md"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import ImageUploadItem from './ImageUploadItem.vue';

import { createVModelHandlers } from '@/helpers/vue';

import MaterialButton from '@/components/MaterialButton.vue';

export default {
  name: 'ImageUpload',

  components: { 
    ImageUploadItem,
    MaterialButton
  },

  props: {
    title: { type: String, default: 'Media' },
    images: { type: Array, default: () => [] },
    showUpdate: { type: Boolean, default: true }
  },

  emits: [
    'update'
  ],

  data() {
    return {
      key: 0
    };
  },

  computed: {
    imagesArePresent() { return this.images.length > 0; },
    
    ...createVModelHandlers(
      'images'
    ),

    filteredImages() { 
      return this.images.filter(i => !i.isDeleted);
    }
  },

  methods: {
    addImage(id, data) {
      const key = this.key++;
      this.imagesHandler = this.imagesHandler.concat({ key, id, data, isNew: true });
    },

    updateImage(image) {
      const index = (this.imagesHandler).findIndex(
        i => i.id === image.id && i.key === image.key
      );

      if (index >= 0) this.imagesHandler[index] = image;
    },

    loadLocalFile(file) {
      var reader = new FileReader();
      reader.onload = () => this.addImage(0, reader.result);

      reader.readAsDataURL(file);
    },

    handleLocalFileUpload(event) {
      for (const image of event.target.files) {
        this.loadLocalFile(image);
      }
    },
  },
}
</script>