import { deleteRequest, getRequest, postRequest, putRequest } from '../..';

/* 
 * For details on the attachmentTypeId, check the
 * ContactApp.Common.Enums.AttachmentType enum in the backend
 */

const createAttachmentsApi = (attachmentTypeId) => {
  return {
    get: (entityId) => getRequest(`/attachment/${attachmentTypeId}/${entityId}`),

    add: (entityId, title, base64FileString) => postRequest(
      `/attachment/${attachmentTypeId}/${entityId}`, 
      { title, attachmentData: base64FileString }
    ),
    
    edit: (attachmentId, title) => putRequest(
      `/attachment/${attachmentId}`, { id: attachmentId, title }
    ),

    remove: (attachmentId) => deleteRequest(`/attachment/${attachmentId}`)
  }
};

export default createAttachmentsApi;