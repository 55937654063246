class ImageScaler {
	static IMAGE_LOAD_TIMEOUT_SECONDS = 10;
	static MAX_IMAGE_WIDTH = 900;
	static MAX_IMAGE_HEIGHT = 700;

	static #getTargetScale = (imageWidth, imageHeight) => {
		const maxWidthToImageWidthRatio = ImageScaler.MAX_IMAGE_WIDTH / imageWidth;
		const maxHeightToImageHeightRatio = ImageScaler.MAX_IMAGE_HEIGHT / imageHeight;

		return maxWidthToImageWidthRatio < maxHeightToImageHeightRatio
			? maxWidthToImageWidthRatio
			: maxHeightToImageHeightRatio;
	}

	static #getImageBase64 = (image) => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		const scale = ImageScaler.#getTargetScale(image.naturalWidth, image.naturalHeight);

		const targetWidth = scale < 1.0
		? image.naturalWidth * scale
		: image.naturalWidth;

		const targetHeight = scale < 1.0
		? image.naturalHeight * scale
		: image.naturalHeight;
		
		canvas.width = targetWidth;
		canvas.height = targetHeight;

		context.drawImage(image, 0, 0, canvas.width, canvas.height);

		return canvas.toDataURL();
	}

	static processImageAsync = (file) => new Promise((resolve, reject) => {
		setTimeout(
			() => reject("Unable to process image"),
			1000 * ImageScaler.IMAGE_LOAD_TIMEOUT_SECONDS
		);

		const img = new Image();

		img.onload = () => {
			const base64 = ImageScaler.#getImageBase64(img);
			resolve(base64);
		};

		img.src = (window.URL || window.webkitURL).createObjectURL(file);
	});
}

export default ImageScaler;