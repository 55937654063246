<template>
  <h5>{{ title }}</h5>
  <div 
    v-if="filesArePresent" 
    class="file-container row mb-3"
  >
    <FileUploadItem 
      v-for="file in filteredFiles"
      :key="file.key"
      :file="file"
      @update="updateFile" 
    />
  </div>
  <p 
    v-if="!filesArePresent"
  >
    Nog geen bijlagen geüpload
  </p>
  <div class="row">
    <div class="col-6">
      <input 
        id="file-upload" 
        class="d-none" 
        type="file" 
        accept="application/pdf" 
        multiple 
        @change="handleLocalFileUpload"
      />
      <label 
        class="btn btn-primary" 
        for="file-upload"
      >
        Selecteer PDF-bestanden
      </label>
    </div>
    <div class="col-6">
      <material-button
        v-if="showUpdate"
        class="float-end mb-0"
        color="dark"
        variant="gradient"
        size="md"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import FileUploadItem from './FileUploadItem.vue';

import { createVModelHandlers } from '@/helpers/vue';

import MaterialButton from '@/components/MaterialButton.vue';

export default {
  components: { 
    FileUploadItem,
    MaterialButton
  },

  props: {
    title: { type: String, default: 'Bijlagen' },
    files: { type: Array, default: () => [] },
    showUpdate: { type: Boolean, default: true }
  },

  emits: [
    'update'
  ],

  data() {
    return {
      key: 0
    };
  },

  computed: {
    filesArePresent() { return this.files.length > 0; },
    
    ...createVModelHandlers(
      'files'
    ),

    filteredFiles() { 
      return this.filesHandler.filter(i => !i.isDeleted);
    }
  },

  methods: {
    addFile(id, fileName, data) {
      const key = this.key++;
      this.filesHandler = this.filesHandler.concat({ 
        key, 
        id, 
        title: fileName,
        data, 
        isNew: true
      });
    },

    updateFile(file) {
      const index = (this.filesHandler).findIndex(
        i => i.id === file.id && i.key === file.key
      );

      if (index >= 0) this.filesHandler[index] = file;
    },

    loadLocalFile(file) {
      var reader = new FileReader();
      reader.onload = () => this.addFile(0, file.name, reader.result);

      reader.readAsDataURL(file);
    },

    handleLocalFileUpload(event) {
      for (const image of event.target.files) {
        this.loadLocalFile(image);
      }
    },
  },
}
</script>