<template>
  <div
    class="position-relative ratio ratio-1x1 border-radius-lg overflow-hidden ms-1"
    :style="size"
  >
    <template v-if="hasSource">
      <img
        :alt="title"
        :src="imageSource"
      />

      <button
        class="summary-visual-remove position-absolute top-0 start-0 bottom-0 p-0 border-0
                w-100 bg-transparent"
        @click="onImageRemoved"
      >
        <div class="remove w-100 h-100 bg-danger" />

        <i
          class="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-2"
        >
          delete_outline
        </i>
      </button>
    </template>

    <label
      v-else
      class="d-flex justify-content-center align-items-center w-100 h-100 m-0 bg-gradient-dark
              cursor-pointer"
      for="image-upload-input"
    >
      <i class="d-block material-icons text-secondary fs-2">
        add_a_photo
      </i>
      
      <input
        id="image-upload-input"
        accept="image/*"
        capture="user"
        class="d-none"
        type="file"
        @change="onImageChanged"
      />
    </label>
  </div>
</template>

<script>

import { createYesNoSweetAlert } from '@/helpers/sweetalert';
import ImageScaler from '@/helpers/image-scaler'

export default {
  props: {
    url: {
      type: String,
      default: undefined
    },

    widthAndHeightInPixels: {
      type: Number,
      default: 96
    }
  },

  emits: [
    'updated'
  ],

  data() {
    return {
      visualImage: undefined,
      imageBase64: undefined,
      title: "",
      deleted: false
    };
  },

  computed: {
    hasSource() {
      return (
        this.imageSource != null &&
        this.imageSource !== ""
      );
    },

    imageSource() {
      return !this.deleted
        ? this.imageBase64 || this.url
        : undefined;
    },
    size() {
      return {
        width: `${this.widthAndHeightInPixels}px`,
        height: `${this.widthAndHeightInPixels}px`
      };
    }
  },

  methods: {
    async onImageChanged({ target }) {
      this.imageBase64 = await ImageScaler.processImageAsync(target.files[0]);

      this.deleted = false;
      this.$emit('updated', this.imageBase64);
    },

    onImageRemoved() {
      createYesNoSweetAlert(
        "Wilt u de afbeeling verwijderen?",
        () => {
          this.deleted = true;
        }
      );
    }
  }
}
</script>

<style scoped>
  img {
    object-fit: cover;
  }

  button {
    transition: opacity .2s;
    opacity: 0;

    &:hover {
      opacity: 0.75;
    }
  }
</style>