<template>
  <div class="d-flex justify-content-between border-bottom mb-2 pb-1">
    <p 
      class="filename d-flex align-items-center mb-0 w-100"
      style="minHeight: 40px"
      >
      <span 
        v-if="isInViewMode"
        @click="enterEditMode"
      >
        {{ fileNameHandler }}
      </span>
      <MaterialInput 
        v-show="isInEditMode"
        v-model="fileNameHandler"
        class="me-5"
        type="text"
        @keydown.enter.prevent="enterViewMode"
      />
    </p>

    <a 
      v-if="file.entityId"
      class="btn btn-sm btn-primary mb-0 me-1 d-flex align-items-center"
      :href="file.attachment"
      title="Openen in nieuw tabblad"
      target="_blank"
    >
      <span 
        class="material-icons"
      >
        open_in_new
      </span>
    </a>

    <button
      class="btn btn-sm btn-primary mb-0 d-flex align-items-center"
      :title="removeButtonTitle"
      @click.prevent="remove"
      >
        <span 
          class="material-icons"
        >
          {{ removeButtonIcon }}
        </span>
    </button>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue"

import { createYesNoSweetAlert } from '@/helpers/sweetalert'

const Modes = {
    EDIT: 0,
    VIEW: 1
}

export default {
  name: 'FileUploadItem',

  components: {
    MaterialInput
  },

  props: {
    file: { type: Object, required: true }
  },

  emits: [
    'update'
  ],

  data() {
    return {
      mode: Modes.VIEW,
      key: 0
    }
  },

  computed: {
    removeButtonTitle() { return this.isExistingFile ? "Bestaand item verwijderen" : "Upload anulleren" },
    removeButtonIcon() { return this.isExistingFile ? "delete" : "close" }, 
    isInViewMode() { return this.mode === Modes.VIEW },
    isInEditMode() { return this.mode === Modes.EDIT },
    isExistingFile() { return this.id > 0; },
    
    fileNameHandler: {
      get() { return this.file.title; },
      set(title) { this.$emit('update', { ...this.file, title, isUpdated: true }); }
    }

  },

  methods: {
    enterEditMode(e) { 
      e.preventDefault();
      this.mode = Modes.EDIT
    },
    
    enterViewMode(e) { 
      e.preventDefault();
      this.mode = Modes.VIEW
    },

    remove() {
      createYesNoSweetAlert(
        'Weet u zeker dat u deze afbeelding wilt verwijderen?',
        () => {
          this.$emit('update', { ...this.file, isDeleted: true });
        }
      );
    },
  }
};
</script>