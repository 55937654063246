import { deleteRequest, postRequest, putRequest } from '../..';

const createImageGalleryApi = (entityTypeId) => {
    return {
        get: (articleId) => postRequest(`/photogallery/${entityTypeId}/${articleId}/1`, {
            currentPage: 0,
            pageSize: 9999
        }),
        add: (articleId, visualImageDataList) => postRequest(
            `/photogallery/images/${entityTypeId}/${articleId}`, { visualImageDataList }
        ),
        update: (articleId, imageId, imageData, sortOrder = 0) => putRequest(
            `/photogallery/images`, {
            photoGalleryId: entityTypeId,
            id: imageId,
            entityId: articleId,
            visualImage: null,
            visualImageData: imageData,
            sortOrder
        }),
        remove: (imageId) => deleteRequest(`/photogallery/images/${imageId}`)
    };
}

export default createImageGalleryApi;